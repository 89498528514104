<template>
  <div>
    <PageLoader :storage="appLoading" />

    <v-layout wrap justify-start pt-3 px-2>
      <v-flex text-left xs12 sm9 md10>
        <span class="itemHeading1"> Notifications </span>
      </v-flex>
    </v-layout>
    <v-layout wrap pt-4 px-4>
      <v-flex xs12 v-if="notifications.length > 0">
        <template v-for="item in notifications">
          <v-card
            :color="item.status == 'unread' ? '#ededed' : '#FFF'"
            class="mt-2"
            :elevation="0"
            :key="item._id"
            @click="routeType(item)"
          >
            <v-layout pa-1 wrap>
              <v-flex
                md1
                style="border: 1px black"
                align-self-center
                text-center
              >
                <v-icon color="#000">mdi-bell</v-icon>
              </v-flex>

              <v-flex md9>
                <v-layout wrap>
                  <v-flex xs12 text-left>
                    <span class="itemKey">{{ item.title }}</span
                    ><br />
                    <span class="itemValue">
                      {{ item.body1 }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- <v-flex md4>
              <v-layout wrap>
                <v-flex xs12 text-left>
                  <span class="itemKey">Notifiction from</span><br>
                   <span class="itemValue">
                     {{ item.fromuid.fullName}}
                  </span>
                </v-flex>
               
              </v-layout>
            </v-flex> -->

              <v-flex md2 align-self-center>
                <span class="itemValue1 pr-3">
                  {{ timeSince(item.create_date) }}
                </span>
                <v-icon v-if="item.status == 'unread'" size="15" color="#5aa9ff"
                  >mdi-checkbox-blank-circle</v-icon
                >
              </v-flex>
            </v-layout>
          </v-card>
        </template>
      </v-flex>
      <v-flex v-else py-2>
        <span class="itemValue">No Notifications Found !! </span>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import Socket from "./../../Sockets/socket";

export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      //   users: [],
      pages: 0,
      count: 20,
      currentPage: this.$route.query.page ? Number(this.$route.query.page) : 1,

      notifications: [],
      notificationData: {},
      unreadNotification: null,
    };
  },

  beforeMount() {
    this.getData();
  },
  // computed:{
  //   newData()
  //   {
  //     return this.notificationData
  //   }
  // },
  mounted() {
    this.socketData();
    // console.log(this.notificationData)
   
      // this.notifications.unshift(this.newData);
    // this.$store.commit("changeNotification", this.unreadNotification);
   
  },
  methods: {
    socketData() {
      Socket.notificationFunction(this); // <---read data
    },
    routeType(item) {
      if(item.status== "unread")
      this.changeStatus(item);
      if (
        item.routetype == "newConflict" ||
        item.routetype == "reportAssign" ||
        item.routetype == "reportResolve" ||
        item.routetype == "reportRelease" ||
        item.routetype == "reportAssignToYou" ||
        item.routetype == "yourReportAssign" ||
        item.routetype == "yourReportResolve" ||
        item.routetype == "yourReportRelease" ||
        item.routetype == "yourReportClose" ||
        item.routetype == "reportLocationUpdate" ||
        item.routetype == "reportTransferRequest"
      ) {
           if(this.$store.state.userData.role=="official")
        this.$router.push("/officers/cases/caseDetails/?id=" + item.routeId1);
        else
        this.$router.push("/admin/cases/caseDetails/?id=" + item.routeId1);
      }
    },
    changeStatus(item) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/notification/markasread/" + item._id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
            this.getData();

              this.appLoading = false;
            }
          }
        })
      .catch((err) => {
        this.appLoading = false;
        console.log(err);
      });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/notifications/all/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.notifications = response.data.data;

              this.appLoading = false;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    timeSince(time) {
      switch (typeof time) {
        case "number":
          break;
        case "string":
          time = +new Date(time);
          break;
        case "object":
          if (time.constructor === Date) time = time.getTime();
          break;
        default:
          time = +new Date();
      }
      var time_formats = [
        [60, "seconds", 1], // 60
        [120, "1 minute ago", "1 minute from now"], // 60*2
        [3600, "minutes", 60], // 60*60, 60
        [7200, "1 hour ago", "1 hour from now"], // 60*60*2
        [86400, "hours", 3600], // 60*60*24, 60*60
        [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
        [604800, "days", 86400], // 60*60*24*7, 60*60*24
        [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
        [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
        [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
        [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
        [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
      ];
      var seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

      if (seconds == 0) {
        return "Just now";
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
      }
      var i = 0,
        format;
      while ((format = time_formats[i++]))
        if (seconds < format[0]) {
          if (typeof format[2] == "string") return format[list_choice];
          else
            return (
              Math.floor(seconds / format[2]) + " " + format[1] + " " + token
            );
        }
      return time;
    },
  },
};
</script>